import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getVideoSubscription, checkIfEmailExists, getAndroidSubscriptionDetails, getUpcomingInvoice, subscriptionUpdate } from "../../Subscription/service";
import { getUserSubscription } from "../../../network/service"
import { registerUser } from '../Register/service';
import { login } from '../Login/service';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { analytics2, authenticate } from "../../../network/service";
import { getUser } from "../../../Redux/UserSlice/UserSlice";
import { getLanguageModal } from '../../../Redux/LanguageModal/languageModalSlice';
import { getAccessToken } from "../../../Redux/AToken/ATokenSlice";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import { getUserSubscriptionData } from "../../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import LoginModal from "../../../Components/Modals/LoginModal/LoginModal";
import { stripeSession, applyCouponCode } from "../../Subscription/service";
import Translate from "../../../Components/Multilanguage/Translate";
import './scss/checkout.css'
import { loadStripe } from '@stripe/stripe-js';
import { useTranslateText } from "../../../Components/Multilanguage/Translate";
const Checkout = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const translateText = useTranslateText();
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user?.value);
    const userData = useSelector((state) => state?.userDetails?.value);
    const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
    const template = projectInfo?.projectConfig?.config?.TEMPLATE;
    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
    };
    const videoId = location?.state?.videoId;
    const showId = location?.state?.showId;
    const channelId = location?.state?.channelId;
    const isFree = location?.state?.isFree;
    const subscriptionId = location?.state?.subscriptionId;
    const subscriptionPrice = location?.state?.subscriptionPrice;
    const tvCode = location?.state?.code;
    const activeLogin = location?.state?.activeLogin;
    const urlParams = new URLSearchParams(window.location.search);
    const androidToken = urlParams.get("antkn");
    const videoIdAndroid = urlParams.get("vd");
    const eventIdAndroid = urlParams.get("ed");
    const channelIdAndroid = urlParams.get("cn");

    const [subscriptions, setSubscriptions] = useState([]);
    const [activeSubscriptions, setActiveSubscriptions] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState();
    const [selectedPlanPrice, setSelectedPlanPrice] = useState();
    const [activeDetailsPlan, setActiveDetailsPlan] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [activeScreen, setActiveScreen] = useState('register');
    const [isOkToProceed, setIsOkToProceed] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [userResponse, setUserResponse] = useState()
    const [error, setError] = useState({});
    const [showAlreadyExistMessage, setShowAlreadyExistMessage] = useState(false);
    const [maskedPassword, setMaskedPassword] = useState();
    const [isFocused, setIsFocused] = useState(false);
    const [resetPasswordMessage, setResetPasswordMessage] = useState();
    const [emailLoginModal, setEmailLoginModal] = useState(false);

    const [haveCoupon, setHaveCoupon] = useState(false);
    const [couponCode, setCouponCode] = useState();
    const [couponSuccess, setCouponSuccess] = useState(false)
    const [couponProcessing, setCouponProcessing] = useState(false)
      const [invoiceDetails, setInvoiceDetails] = useState("")
      const [showConfirmWindow, setShowConfirmWindow] = useState(false);
      const [stripeDeatils, setStripeDeatils] = useState(null);
      const [showModal, setShowModal] = useState(false);
      const [showFailModal, setShowFailModal] = useState(false);
      const [showSucessModal, setShowSucessModal] = useState(false);
      const [showProcessing, setShowProcessing] = useState(false);
      const [planDetails, setPlanDetails] = useState(null);

    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (location?.state?.type === "upgrade") {
            localStorage.setItem('previousSubId', location?.state?.subId);
        } else {
            // go on
        }
    }, [])

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        fetchSubscriptionList();
        user && setActiveScreen('login-success');

        if (queryParams.get("new-login") === "1") {
            setActiveScreen('login');
        } else {
            //leave it
        }

        localStorage.removeItem("couponId");
        setHaveCoupon(false);

    }, []);

    useEffect(() => {

        if (androidToken) {
            const deviceId = urlParams.get("deviceId");
            const info = {
                projectConfig: projectInfo?.projectConfig,
                geoInfo: projectInfo?.geoInfo,
                device_id: deviceId,
            };
            fetchAndroidSubscriptionDetails(androidToken, info);
        } else {
            // do nothing
        }

    }, [androidToken])

    useEffect(() => {

        if (videoId || showId) {
            fetchSubscriptionList();
            user && setActiveScreen('login-success');
        } else {
            //leave now
        }

    }, [videoId, showId])

    useEffect(() => {
        activeLogin && setActiveScreen('login')
    }, [activeLogin])

    useEffect(() => {
        if (selectedPlan) {
            localStorage.setItem("selectedSubId", selectedPlan);
            localStorage.setItem("deviceType", androidToken || localStorage.getItem('isAndroid') == 'true' ? "android-web" : "web");
            localStorage.setItem("selectedAmount", selectedPlanPrice);
            localStorage.removeItem("couponId");
            setHaveCoupon(false);
        } else {
            //keep waiting
        }
        subscriptions.map((item) => {
            if(selectedPlan == item?.subscription_id) {
                setPlanDetails(item);
            }
        })
    }, [selectedPlan])

    useEffect(() => {
        if (password) {
            const mask = '*'?.repeat(password.length);
            setMaskedPassword(mask);
        } else {
            setMaskedPassword();
        }
    }, [password]);

    useEffect(() => {
        if (activeScreen == 'register' || activeScreen == 'login-success') {
            setIsOkToProceed(true)
        } else {
            setIsOkToProceed(false)
        }

        setHaveCoupon(false)

    }, [activeScreen])

    useEffect(() => {
        if (!haveCoupon) {
            setError({
                ...error,
                ['coupon']: ''
            });
            setCouponCode();
        } else {
            //do nothing
        }
    }, [haveCoupon])

    useEffect(() => {
        if (showAlreadyExistMessage) {
            setTimeout(() => {
                setShowAlreadyExistMessage(false)
            }, 15 * 1000);
        }
    }, [showAlreadyExistMessage])

    useEffect(() => {
        if (resetPasswordMessage) {
            setTimeout(() => {
                setResetPasswordMessage('')
            }, 5 * 1000);
        }
    }, [resetPasswordMessage])

    useEffect(() => {

        if (userSubscriptionData?.data?.length > 0) {
            const haveValidSubscription = subscriptionCheck();

            if (haveValidSubscription) {
                if (queryParams.get("new-login") === "1") {
                    navigate(-2)
                } else {
                    navigate(-1)
                }
            } else {
                // fail silently
            }

        } else {
            // fail silently
        }

    }, [userSubscriptionData])

    const fetchAndroidSubscriptionDetails = async (androidToken, info) => {

        const data = {
            projectDetails: info,
            accessToken: accessToken,
        };
        const response = await getAndroidSubscriptionDetails(
            data,
            androidToken
        );

        if (response?.status === 200) {

            localStorage.setItem("userId", response?.data?.data[0]?.user_id);
            localStorage.setItem("isAndroid", true);
            localStorage.setItem("deviceType", "android-web");
            localStorage.setItem('selectedLanguageCode', response?.data?.data[0]?.language?.short_code);
            localStorage.setItem('selectedLanguageId', response?.data?.data[0]?.language?.language_id);
            updateLocalStorage(response)
            setUserResponse(response)
            dispatch(
                getLanguageModal({
                    languageModal: {
                        selectedLanguageCode: response?.data?.data[0]?.language?.short_code
                    }
                }) )
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);

            // Remove the 'antkn & deviceId' parameters
            params.delete('antkn');
            params.delete('deviceId');

            const newUrl = `${url.pathname}?${params.toString()}`;
            window.location.href = newUrl;
        }
    };


    const fetchSubscriptionList = async () => {

        let typeID;
        let typeName;

        if (videoIdAndroid) {
            typeID = videoIdAndroid;
            typeName = 'video';
        } else if (eventIdAndroid) {
            typeID = eventIdAndroid;
            typeName = 'event';
        } else if (channelIdAndroid) {
            typeID = channelIdAndroid;
            typeName = 'channel';
        } else if (videoId) {
            typeID = videoId;
            typeName = 'video';
        } else if (showId) {
            typeID = showId;
            typeName = 'show';
        } else if (channelId) {
            typeID = channelId;
            typeName = 'channel';
        } else if (location?.state?.type === "upgrade") {
            typeID = location?.state?.subId;
            typeName = null;
        }

        const response = await getVideoSubscription(appInfo, typeID, typeName, 'list');
        const subscriptionResponse = await getVideoSubscription(appInfo, typeID, typeName);

        if (subscriptionResponse?.status === 200) {
            setActiveSubscriptions(subscriptionResponse?.data?.data);
        }

        if (response?.status === 200) {
            setSubscriptions(response?.data?.data);

            if (subscriptionId) {

                await fetchUserSubscriptionDetails()


                setSelectedPlan(subscriptionId)
                setSelectedPlanPrice(subscriptionPrice)
            } else {
                setSelectedPlan(response?.data?.data[0]?.subscription_id)
                setSelectedPlanPrice(response?.data?.data[0]?.price)
            }

        }
    };

    const fetchUserSubscriptionDetails = async () => {
        const userSubResponse = await getUserSubscription(appInfo);
        if (userSubResponse?.status === 200) {
            dispatch(
                getUserSubscriptionData({
                    userSubscription: userSubResponse?.data,
                })
            );
        }
    };

    const linkHandler = (path) => {
        navigate(path);
    }

    const handleEmailValidation = async () => {

        if (activeScreen !== 'register') {
            return false;
        } else {

            const isEmailValid = await validateEmail(email);
            if (isEmailValid) {
                setError({
                    ['email']: '',
                    ['password']: ''
                });
                // register case: check whether email already exist or not.

                email && isEmailAlreadyRegistered()
            } else {
                setError({
                    ['email']: <Translate textKey={'invalid_email_id'}/>,
                    ['password']: ''
                });
            }
        }

    }

    const validateEmail = async (email) => {
        const regex = /^[^@]+@[^@]+\.[^@]+$/;
        return regex.test(email);
    };

    const validatePassword = async (password) => {

        if (password && password != '') {
            return true;
        } else {
            setError({
                ...error,
                ['password']: <Translate textKey={'password_required'}/>
            })
            return false;
        }
    }

    const isEmailAlreadyRegistered = async () => {
        const response = await checkIfEmailExists(appInfo, { user_email: email });

        if (response?.status == 201) {
            // toast.error(response?.data?.message)
            setShowAlreadyExistMessage(true);
            setActiveScreen('login')
        } else {
            console.log(response?.data?.message)
        }
    }

    const handleLoginSubmit = async () => {

        const isEmailValid = await validateEmail(email);
        const isPasswordValid = await validatePassword(password);

        if (isEmailValid && isPasswordValid) {
            setError({})
            const response = await login({ email, password }, appInfo);
            if (response?.status === 200) {
                updateLocalStorage(response)
                setUserResponse(response)
                setActiveScreen('login-success') // display user details and info like please select a plan and click on the continue button to proceed the payment.
            } else if (response?.status == 202) {
                setResetPasswordMessage(response?.data?.message)
            }
        } else {
            manageErrorMessage(isEmailValid, isPasswordValid);
        }


    }

    const subscriptionCheck = () => {
        const userSub = userSubscriptionData?.data;
        const videoSub = activeSubscriptions;
        return userSub?.some((user) => videoSub?.some((video) => user?.sub_id === video?.subscription_id));
    };

    const handleSubmit = async () => {

        if (activeScreen == 'register') {
            const isEmailValid = await validateEmail(email);
            const isPasswordValid = await validatePassword(password);

            if (isEmailValid && isPasswordValid) {
                const response = await registerUser(appInfo, { email, password });
                if (response?.status == 200) {
                    setUserResponse(response)
                    updateLocalStorage(response)
                    // continue the payment flow here
                    proceedToPayment()
                }

            } else {
                manageErrorMessage(isEmailValid, isPasswordValid);
            }
        } else {
            if (user) {
                proceedToPayment();
            } else {
                switchToAnotherAccount()
                setResetPasswordMessage('Invalid credentials');
            }

        }

    }

    const proceedToPayment = async () => {

        if (selectedPlan == 0) { // Free Account scenario
            navigate(-1)
        } else {
            if (couponSuccess) {
                const paymentInfo = {
                    subId: selectedPlan,
                    couponId: couponCode?.couponId,
                };
                try {
                    const stripeResponse = await stripeSession(appInfo, paymentInfo);
                    if (stripeResponse?.status === 200) {
                        window.open(stripeResponse?.data?.url, "_self")

                    }
                } catch (err) {
                    toast.error(err?.response?.data?.message, {
                        position: "bottom-center",
                    });
                }
            } else {
                const paymentInfo = {
                    subId: selectedPlan,
                };
                try {
                    const response = await stripeSession(appInfo, paymentInfo);

                    if (response?.status === 200) {
                        window.open(response?.data?.url, "_self")
                    }
                } catch (err) {
                    // toast.error(err?.response?.data?.message, {
                    //     position: "bottom-center",
                    // });
                }
            }

        }

    }

    const updateLocalStorage = async (response) => {

        const userDetails = response ? response?.data?.data[0] : userResponse?.data?.data[0];
        if (userDetails?.user_id) {
            localStorage.setItem("userId", userDetails?.user_id);
            localStorage.setItem("selectedSubId", selectedPlan);
            localStorage.setItem("deviceType", androidToken || localStorage.getItem('isAndroid') == 'true' ? "android-web" : "web");
            localStorage.setItem("selectedAmount", selectedPlanPrice);

            tokenAuthenticate();
            updateDeviceAnalytics(
                userDetails?.user_id,
                appInfo,
                userDetails?.user_email
            );
            dispatch(
                getUser({
                    user: userDetails?.user_id,
                })
            );

            dispatch(
                getUserDetails({
                    userDetails
                }));

            await fetchUserSubscriptionDetails();



        } else {
            console.log('user details not found');

        }
    }

    const updateDeviceAnalytics = async (userId, appInfo, userEmail) => {
        await analytics2(userId, appInfo, userEmail);
    };

    const tokenAuthenticate = async () => {
        const response = await authenticate(appInfo);
        if (response?.status === 200) {
            localStorage.setItem('selectedLanguageCode', response?.data?.user_language?.short_code);
            localStorage.setItem('selectedLanguageId', response?.data?.user_language?.language_id);
            dispatch(
                getLanguageModal({
                    languageModal: {
                        selectedLanguageCode: response?.data?.user_language?.short_code
                    }
                }))
            dispatch(
                getAccessToken({
                    accessToken: response?.data?.token,
                })
            );
            dispatch(
                getUserDetails({
                    userDetails: response?.data,
                })
            );
        }
    };

    const switchToAnotherAccount = () => {
        localStorage.clear();
        setActiveScreen('login');
        setEmail();
        setPassword();
        setMaskedPassword();
        dispatch(
            getUser({
                user: null,
            })
        );
        window.location.href = window.location.href + '?new-login=1';
    }



    const manageErrorMessage = (isEmailValid, isPasswordValid) => {

        const passwordMsg = isPasswordValid ? '' : <Translate textKey={'password_required'}/>;
        const emailMsg = isEmailValid ? '' : <Translate textKey={'email_required_message'}/>;
        setError({
            password: passwordMsg,
            email: emailMsg
        });
    }

    const applyCouponCodeHandler = () => {

        setCouponProcessing(true);

        if (!couponCode?.couponCode) {
            setCouponProcessing(false);
            setError({
                ...error,
                ['coupon']: <Translate textKey={'enter_coupon_code'}/>
            });
        } else {
            couponCodeApi();
        }
    };

    const couponCodeApi = async () => {
        try {
            const couponCodeResponse = await applyCouponCode(
                appInfo,
                couponCode?.couponCode,
                selectedPlan
            );

            if (couponCodeResponse?.status === 200) {
                setCouponProcessing(false);
                setError({
                    ...error,
                    ['coupon']: ''
                })
                setCouponSuccess(true);
                localStorage.setItem("couponId", couponCodeResponse?.data?.data?.coupon_id);

                setCouponCode({
                    ...couponCode,
                    couponId: couponCodeResponse?.data?.data?.coupon_id,
                });

            } else {
                setCouponProcessing(false);
                setError({
                    ...error,
                    ['coupon']: couponCodeResponse?.data?.message
                });
            }
        } catch (err) { }
    };

      const handleUpcomingInvoice = async (planDetails) => {
        setShowModal(true);
        const invoiceDetails = await getUpcomingInvoice(planDetails, appInfo)
          if(invoiceDetails?.status == 200) {
            const responseData = invoiceDetails?.data
            if (responseData?.success) {
              setShowModal(false);
              setShowConfirmWindow(true)
              const paymentDetails = responseData?.data
              let {
                amount_due,
                unused_time,
                plan_amount,
                description
              } = paymentDetails
              let details = {
                due: amount_due,
                discount: unused_time,
                plan: plan_amount,
                description: description
              };
              setInvoiceDetails(details);
              setStripeDeatils(planDetails);
            } else {
                setShowModal(false);
                setShowFailModal(true);
                setInvoiceDetails("No upcoming invoice for this user")
            }
          } else {
            setShowModal(false);
            setShowFailModal(true);
            setInvoiceDetails("No upcoming invoice for this user")
          }
      }
    
      const handleClick = async (modal, check) => {
        if(modal == "postPayment") {
          if (check == "success") {
            setShowSucessModal(false);
            navigate("/account")
          }
          if(check == "Fail") {
            setShowFailModal(false);
            setShowProcessing(false);
          }
        }
        if(modal == "payment") {
          if(check) {
            setShowConfirmWindow(false);
            setShowModal(true);
            setShowProcessing(true);
            const stripeUpdate = await subscriptionUpdate(stripeDeatils, appInfo)
            if(stripeUpdate?.status == 200) {
                setShowModal(false);
                var stripeData = stripeUpdate?.data;
                if (stripeData?.requires_action) {
                    setShowModal(true)
                    let accessKey = stripeData?.access_key;
                    let clientSecret = stripeData?.client_secret;
                    const stripe = await loadStripe(accessKey);
                        const stripePayment = await stripe.confirmCardPayment(clientSecret);
                        if (stripePayment?.error) {
                            setShowModal(false)
                            navigate("/failed")
                            window.scrollTo({ top: 0, behavior: 'smooth'});
                        } else if (stripePayment.paymentIntent && stripePayment.paymentIntent.status === 'succeeded') {
                            let authenticated = true;
                            const stripeUpdate = await subscriptionUpdate(stripeDeatils, appInfo, authenticated)
                            if (stripeUpdate?.status == 200) {
                                setShowModal(false)
                                setShowSucessModal(true);
                            } else {
                                setShowModal(false)
                                navigate("/failed")
                                window.scrollTo({ top: 0, behavior: 'smooth'});
                            }
                        } else {
                            navigate("/failed")
                            window.scrollTo({ top: 0, behavior: 'smooth'});
                        }
                } else if (stripeData?.success) {
                    setShowSucessModal(true);
                } else {
                    navigate("/failed")
                    window.scrollTo({ top: 0, behavior: 'smooth'});    
                }
            } else {
                setShowModal(false);
                navigate("/failed")
                window.scrollTo({ top: 0, behavior: 'smooth'});
            }
          } else {
            setShowConfirmWindow(false);
          }
        }
      }

    return (
        <>
        {showConfirmWindow && !showModal? (
            <>
              <div className="paymentConfirm ontop">
                <div className="paymentConfirmContainer align-center">
                  <div>
                    <h5><Translate textKey={'payment_confirm'}/></h5>
                  <div class="amount-summary">
                    <div class="row">
                      <span class="label">{invoiceDetails?.description?.plan_amount}</span>
                      <span class="value">{invoiceDetails?.plan}</span>
                    </div>
                    <div class="row">
                      <span class="label">{invoiceDetails?.description?.unused_time}</span>
                      <span class="value">{invoiceDetails?.discount}</span>
                    </div>
                    <hr/>
                    <div class="row">
                      <span class="label amount-due">{invoiceDetails?.description?.amount_due}</span>
                      <span class="value amount-due">{invoiceDetails?.due}</span>
                    </div>
                  </div>
                  <div className="button-container df">
                    <button
                      onClick={() => {
                        handleClick("payment",true);
                      }}
                    >
                      <Translate textKey={'ok'}/>
                    </button>
                    <button
                      onClick={() => {
                        handleClick("payment",false);
                      }}
                    >
                     <Translate textKey={'cancel'}/>
                    </button>
                  </div>  
                  </div>
                </div>
              </div>
            </>
          ) :null}
          {showSucessModal ? (<div className="success ontop">
            <div className="successContainer align-center">
              <div className="checkIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="92"
                  height="92"
                  fill="#fff"
                  className="bi bi-check2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                </svg>
              </div>
              <h5><Translate textKey={'success'}/></h5>
              <p><Translate textKey={'payment_success_message'}/></p>
              <button onClick={() => handleClick("postPayment","success")}><Translate textKey={'ok'}/></button>
            </div>
          </div>) : null}
          {showModal ? (<div className="paymentConfirm ontop">
            <div className="paymentConfirmContainer align-center min-ht">
              {!showProcessing ? <p className="checking"><Translate textKey={'loading'}/></p> : <p className="checking"><Translate textKey={'payment_processing'}/></p>}
    
            </div>
          </div>) : null}
          {showFailModal ? (<div className="paymentConfirm ontop">
            <div className="paymentConfirmContainer align-center min-ht">
              <div>
              <p className="checking">{invoiceDetails}</p>
              <div className = "button-container df" >
                <button onClick={() => handleClick("postPayment","Fail")}><Translate textKey={'ok'}/></button> 
              </div>
              </div>
            </div>
          </div>) : null}
        <div className="checkout-page">
            <style>
                {`
                    .dynamic-styles {
                        background: ${projectInfo?.projectConfig?.config.BUTTON_COLOR};
                        color:${projectInfo?.projectConfig?.config.TEXT_COLOR};
                    }
                `}
            </style>
            <div className="wrapper">
                {
                    emailLoginModal &&
                    <LoginModal isEmailForm={true} setEmailLoginModal={setEmailLoginModal} tvCode={tvCode} />
                }
                <div className="left">
                    <div>
                        <span className="heading">
                            {activeScreen == 'register' ? <Translate textKey={'create_your_account'}/> : activeScreen == 'login-success' ? <Translate textKey={'account_confirmation'}/> : <Translate textKey={'access_your_account'}/>}
                        </span>
                        {activeScreen != 'login-success' && <div className="sign-in-link-section">
                            {activeScreen == 'register' ? <>
                                <span className="sub-heading"><Translate textKey={'register_sign_in_text'} /></span>

                                <span
                                    className={`signin-link ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`}
                                    onClick={() => setActiveScreen('login')}
                                >
                                    <Translate textKey={'sign_in'}/>
                                </span>


                            </>
                                : <>
                                    <span className="sub-heading"><Translate textKey={'create_new_account'}/></span>
                                    <span className={`signin-link ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`} onClick={() => { setActiveScreen('register') }}><Translate textKey={'register'}/></span>
                                </>}
                        </div>}

                    </div>
                    {activeScreen != 'login-success' ? <div className="user-section">

                        {projectInfo?.projectConfig?.config?.LOGIN_WITH_MAGIC_LINK_REQUIRED === "true" && activeScreen == 'login' &&
                            <> <div className="login-with-email-wrapper"
                                onClick={() => {
                                    localStorage.setItem('redirectFromNewCheckoutPage', 'true')
                                    setEmailLoginModal(true);
                                }}
                            >
                                <button className={`dynamic-styles ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`}><Translate textKey={'sign_in_via_email'}/></button>
                            </div>

                                <div className="seperate"><div className="line"></div><span className="devider"><Translate textKey={'or'}/></span><div className="line"></div></div>
                                <div className="login-with-email-password">
                                    <span><Translate textKey={'sign_in_via_email_password'}/></span>
                                </div>
                            </>
                        }
                        <div>
                            <input type="email" placeholder={translateText("email")}
                                onChange={(event) => {
                                    setEmail(event?.target?.value);
                                }}

                                onFocus={() => { setError({ ...error, ['email']: '' }) }}


                            />

                            {showAlreadyExistMessage && <div className="existing-user-message"><span className="close-message"
                                onClick={() => { setShowAlreadyExistMessage(false) }}>x</span><p><Translate textKey={'existing_user_message'}/></p></div>}

                            {error?.email && <span className="error">{error.email}</span>}
                        </div>
                        <div className="password-section">
                            {isFocused || showPassword ? <>{password && <span className={`password-show ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`}
                                onClick={() => { setShowPassword(false) }}
                            ><svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2 2L22 22" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></span>
                            }</> : <>{password && <span className="password-show"
                                onClick={() => { setShowPassword(true) }}
                            ><svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <circle cx="12" cy="12" r="3" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></circle> </g></svg></span>}</>}

                            <input type='text'
                                placeholder={translateText(activeScreen == 'register' ? "create_password" : 'password')}
                                onFocus={() => {
                                    handleEmailValidation();
                                    setIsFocused(true); // Mark as focused to show the actual password while editing
                                }}
                                value={isFocused || showPassword ? password : maskedPassword}
                                onChange={(event) => {
                                    setPassword(event?.target?.value);
                                }}
                                onBlur={() => setIsFocused(false)}
                            />
                            {error?.password && <span className="error">{error.password}</span>}
                            {resetPasswordMessage && activeScreen == 'login' &&
                                <div>
                                    <p className="login-success-message error">{resetPasswordMessage}</p>
                                </div>}

                            {!resetPasswordMessage && activeScreen == 'login' && projectInfo?.projectConfig?.config?.CUSTOM_LOGIN_SCREEN_MESSAGE &&
                                <div>
                                    <p className="login-success-message error">{projectInfo?.projectConfig?.config?.CUSTOM_LOGIN_SCREEN_MESSAGE}</p>
                                </div>}


                        </div>
                        {activeScreen == 'login' && <div className="forgot-link">
                            <Link to={'/forgot-password'}><div className={`sub-heading link forgot-text ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`}><Translate textKey={'forgot_password'}/></div></Link>
                            <button className={`login-button dynamic-styles ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`} onClick={() => { handleLoginSubmit() }}><Translate textKey={'sign_in'}/></button>
                        </div>}
                    </div> : <div className="user-section">
                        {userData && <p className="login-success-message"><Translate textKey={'logged_as'}/> <span className={`link ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`}>{userData?.user_email}</span>. <Translate textKey={'select_plan_message'}/></p>}
                        <>
                            <span className="sub-heading"><Translate textKey={'switch_account'}/></span>
                            <span className={`link ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`} onClick={() => { switchToAnotherAccount() }}><Translate textKey={'sign_in'}/></span>
                        </>
                    </div>}

                    {activeScreen != 'login' && <div className="coupon-wrapper">
                        <div className="coupon-link" >
                            <input type="checkbox" className="coupon-check" checked={haveCoupon} onChange={() => { setHaveCoupon(!haveCoupon) }} />
                            <div className={`sub-heading link forgot-text coupon-text ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`} onClick={() => { setHaveCoupon(!haveCoupon) }}><Translate textKey={'having_coupon'}/></div>
                        </div>
                        {
                            haveCoupon &&
                            <div className="coupon-section">
                                <input type='text' placeholder={translateText("enter_coupon_code")} onFocus={() => {
                                    setError({
                                        ...error,
                                        ['coupon']: ''
                                    })
                                }}

                                    onChange={(event) => {
                                        setCouponCode({
                                            ...couponCode,
                                            ['couponCode']: event?.target?.value
                                        })
                                    }} />
                                <button className={`dynamic-styles ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`} onClick={() => { !couponProcessing && applyCouponCodeHandler() }}>{couponProcessing ? <Translate textKey={'processing'}/> : <Translate textKey={'apply'}/>}</button>
                            </div>
                        }
                        {error?.coupon && <span className="error">{error.coupon}</span>}
                        {couponSuccess && <span className="success">{'The coupon has been applied successfully!'}</span>}
                    </div>}
                </div>
                <div className="right">
                    <div>
                        <span className="heading">
                            <Translate textKey={'select_package'}/>
                        </span>
                    </div>
                    <div className="package-section">

                        {
                            subscriptions.length > 0 ? subscriptions.map((item, index) => {
                                return <div className="package-item">
                                    <div className="heading-section">
                                        <div className="heading-wrapper">
                                            <span className="heading">{item?.subscription_name}</span>
                                            <span className="sub-heading">{item?.subscription_text}</span>
                                        </div>
                                        <div className="plan-options-wrapper">
                                            <input type="radio" id={`selected-plan-${index}`} name="selected-plan" checked={selectedPlan == item?.subscription_id ? true : false} onChange={() => { setSelectedPlan(item?.subscription_id); setSelectedPlanPrice(item?.price) }} className="selected-plan plan-options" />
                                        </div>
                                    </div>
                                    {activeDetailsPlan == item?.subscription_id && <div className="plan-description">{item?.description}</div>}
                                    <div className={`details ${template === 'TEMPLATE_9' ? 'template-9-color' : ''} `} onClick={() => { activeDetailsPlan ? setActiveDetailsPlan() : setActiveDetailsPlan(item?.subscription_id) }}>
                                        {activeDetailsPlan == item?.subscription_id ? <Translate textKey={'see_less'}/> : <Translate textKey={'see_details'}/>}
                                    </div>
                                </div>
                            }) : !isFree &&
                            <div className="package-item">
                                <div className="heading-section">
                                    <div className="heading-wrapper">
                                        <span className="sub-heading"><Translate textKey={'subscription_fetch'}/></span>
                                    </div>
                                </div>
                            </div>
                        }

                        {isFree &&
                            <div className="package-item">
                                <div className="heading-section">
                                    <div className="heading-wrapper">
                                        <span className="heading"><Translate textKey={'free_account'}/></span>
                                        <span className="sub-heading"><Translate textKey={'watch_for_free'}/></span>
                                    </div>
                                    <div className="plan-options-wrapper">
                                        <input type="radio" id={`selected-plan-free`} name="selected-plan" checked={subscriptions.length == 0 || selectedPlan == 0} onChange={() => { setSelectedPlan(0); setSelectedPlanPrice(0) }} className="selected-plan plan-options" />
                                    </div>
                                </div>
                            </div>
                        }

                        <div>
                            <p className="disclaimer-text">
                                <Translate textKey={'disclaimer-text'}/> {projectInfo.projectConfig.config.TAB_TITLE}'s &nbsp;
                                <span className={`link   ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`} onClick={() => { linkHandler('/terms-and-conditions') }}><Translate textKey={'terms_of_use'}/></span> <Translate textKey={'and'}/> &nbsp;
                                <span className={`link  ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`} onClick={() => { linkHandler('/privacy-policy') }}><Translate textKey={'privacy_policy'}/></span> <Translate textKey={'disclaimer-text_two'}/> &nbsp;
                                 {projectInfo.projectConfig.config.TAB_TITLE} <Translate textKey={'disclaimer-text_three'}/>
                            </p>
                        </div>

                        <div>
                            {isOkToProceed &&
                                <button className={`continue-button dynamic-styles ${template === 'TEMPLATE_9' ? 'template-9-color' : ''}`}
                                    onClick={() => {
                                        location?.state?.type == "upgrade"
                                            ? handleUpcomingInvoice(planDetails)
                                            : handleSubmit();
                                    }}>
                                    {location?.state?.type == "upgrade" ? <Translate textKey={'update'}/> : <Translate textKey={'continue'}/>}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>

    )
}

export default Checkout;