import React, { useState, useEffect, useRef } from 'react';
import GaiaCard from '../../../../Components/ShowCard/ShowCard'; //change to GaiaCard when mergin GaiaTemplate
import ShowCard from '../../../../Components/ShowCard/ShowCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import 'swiper/swiper-bundle.min.css';
import { useWindowWidth } from '@react-hook/window-size';
import { ReactComponent as ArrowIcon } from "../../../../assets/Icons/arrownext.svg";
import Translate from '../../../../Components/Multilanguage/Translate';

const MoreTopics = ({ data }) => {
  const dataArray = data?.shows;
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const [activeTopic, setActiveTopic] = useState(dataArray[0]?.category_name || '');
  const swiperRef = useRef(null);
  const topicsSwiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const cardCounts = dataArray.reduce((acc, item) => {
    acc[item.category_name] = item.shows?.length || 0;
    return acc;
  }, {});
  const [activeTopicData, setActiveTopicData] = useState(dataArray.find(item => item.category_name === activeTopic));
  const [numCards, setNumCards] = useState(cardCounts[activeTopic] || 0);
  const windowSize = useWindowWidth();
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;

  const thumbnailOrientation = data?.shows?.thumbnail_orientation ? data?.shows?.thumbnail_orientation : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const [slidesConfig, setSlidesConfig] = useState(
    thumbnailOrientation === "PORTRAIT" ? { slidesPerView: 8, slidesPerGroup: 8 } : { slidesPerView: 6, slidesPerGroup: 6 }
  );
  
  const [rowItemCountNumber, setRowItemCountNumber] = useState(5);
  const [sliderItemCount, setSliderItemCount] = useState(6);
  const [topics, setTopics] = useState(dataArray.map(item => item.category_name));
  const [displayedData, setDisplayedData] = useState(activeTopicData?.shows || []);
  const [topRowCards, setTopRowCards] = useState(thumbnailOrientation === "PORTRAIT" ? Math.min(5, numCards) : Math.min(4, numCards));
  const [sliderCards, setSliderCards] = useState(activeTopicData?.shows?.slice(topRowCards) || []);
  const [isLTRActive, setIsLTRActive] = useState(false);



  const updateSlidesConfig = () => {
    const width = window.innerWidth;
    const config = thumbnailOrientation === "PORTRAIT" 
    ? { slidesPerView: 8, slidesPerGroup: 8 } 
    : { slidesPerView: 6, slidesPerGroup: 6 };

  if (width > 980) setSlidesConfig(config);
  else if (width >= 768) setSlidesConfig({ slidesPerView: 2, slidesPerGroup: 2 });
  else setSlidesConfig({ slidesPerView: 1, slidesPerGroup: 1 });
  };

  useEffect(()=>{
    setActiveTopicData(dataArray.find(item => item.category_name === activeTopic));
    setNumCards(cardCounts[activeTopic] || 0);
  },[activeTopic])

  useEffect(() => {
    updateSlidesConfig();
    window.addEventListener('resize', updateSlidesConfig);
    return () => window.removeEventListener('resize', updateSlidesConfig);
  }, []);

  const onSlideChange = (swiper) => {
    if (swiper && swiper.params) {
      const lastSlideIndex = swiper.activeIndex + (swiper.params.slidesPerView - 1);
  
      if (lastSlideIndex >= swiper.slides.length) {
        setActiveIndex(swiper.slides.length - 1);
      } else {
        setActiveIndex(lastSlideIndex);
      }
    }
  };

  const handleSwiperInit = (swiperInstance) => {
    if (swiperInstance) {
      swiperRef.current = swiperInstance;
      onSlideChange(swiperInstance);
    }
  };

  useEffect(()=>{
    setTopRowCards(thumbnailOrientation === "PORTRAIT" ? Math.min(5, numCards) : Math.min(4, numCards));
  },[numCards])

  useEffect(() => {
    if (sliderCards > 0 && swiperRef.current) {
      handleSwiperInit(swiperRef.current);
    } else {
      swiperRef.current = null; 
    }
  }, [activeTopic, sliderCards]);

  useEffect(() => {
    const newDisplayedData = activeTopicData?.shows || [];
    const newSliderCards = newDisplayedData?.slice(topRowCards);

    if (isRTL && numCards > rowItemCountNumber) {
      setDisplayedData([...newDisplayedData]?.reverse());
      setSliderCards([...newSliderCards]?.reverse());
    } else {
      setDisplayedData(newDisplayedData);
      setSliderCards(newSliderCards);
    }
  }, [isRTL, activeTopicData, numCards, rowItemCountNumber, topRowCards]);

  useEffect(() => {
    if(windowSize > 980){
      if(thumbnailOrientation === "LANDSCAPE"){
      setRowItemCountNumber(10);
      setSliderItemCount(6);
      } else if (thumbnailOrientation === "PORTRAIT"){
        setRowItemCountNumber(13);
        setSliderItemCount(8);
      }
    }
    else{
    if (windowSize > 767){
      setRowItemCountNumber(3);
    } else if (windowSize > 479){
      setRowItemCountNumber(2);
    } else if (windowSize > 319){
      setRowItemCountNumber(1);
    }
  }
  }, [projectInfo, windowSize, thumbnailOrientation]);

  useEffect(()=>{
    if(isRTL && isLTRActive){
      setTopics(dataArray.slice().reverse().map(item => item?.category_name));
    } else {
      setTopics(dataArray.map(item => item?.category_name));
    }
  },[isRTL,isLTRActive,dataArray])

  const handleSlideChange = (swiper) => {
    if (!swiper) return;

    // const totalSlidesWidth = swiper?.slidesGrid[swiper.slidesGrid.length - 1] + swiper?.slides[0]?.offsetWidth; 
    const tempContainer = document.createElement('div');
    tempContainer.style.visibility = 'hidden';
    tempContainer.style.position = 'absolute';
    tempContainer.style.display = 'flex';  
    tempContainer.style.whiteSpace = 'nowrap';
    
    topics.forEach(topic => {
      const span = document.createElement('span');
      span.textContent = topic;
      span.style.marginRight = '30px';
      span.style.fontSize = '16px';
      tempContainer.appendChild(span);
    });
  
    document.body.appendChild(tempContainer);
    const totalSlidesWidth = tempContainer.offsetWidth;
    document.body.removeChild(tempContainer);
    
    // const containerWidth = swiper.wrapperEl.offsetWidth; 
    const containerTemp = document.createElement('div');
    containerTemp.style.visibility = 'hidden';
    containerTemp.style.position = 'absolute';
    containerTemp.style.width = '100%';
    document.body.appendChild(containerTemp);
    const containerWidth = containerTemp.offsetWidth;
    document.body.removeChild(containerTemp);
    
    if (isRTL && (totalSlidesWidth + 88 > containerWidth)) {
      setIsLTRActive(true);
    } else {
      setIsLTRActive(false);
    }
  };

  useEffect(() => {
    if (topicsSwiperRef.current) {
      handleSlideChange(topicsSwiperRef.current);
    }
  }, [windowSize]);

  const isSingleCard = activeTopicData?.shows?.length === topRowCards + 1;

  const renderCards = () => {
    if (numCards === 0) return null;

    return (
      <>
        {topRowCards > 0 && windowSize > 980 && (
          <div className="topRow">
            {activeTopicData?.shows?.slice(0,topRowCards)?.map((show,index) => (
              <div key={index} className={`topCard ${thumbnailOrientation === "PORTRAIT" ? "portrait" : "landscape"}`}>
                {template === "TEMPLATE_10" ? (
                <GaiaCard
                    data={show}
                    thumbnailOrientation={thumbnailOrientation}
                    season={false}
                    metaData={true}
                    type={show?.type}
                />
                ) : (
                <ShowCard
                    data={show}
                    thumbnailOrientation={thumbnailOrientation}
                    season={false}
                    metaData={true}
                    type={show?.type}
                />
                )}
              </div>
            ))}
          </div>
        )}
        {sliderCards.length > 0 && windowSize > 980 && (
          <Swiper
            key={isRTL ? (sliderCards.length > sliderItemCount ? "ltr" : windowSize < 980 ? "rtl" : "rtl") : ""}
            modules={[Navigation]}
            navigation
            spaceBetween={10}
            slidesPerView={slidesConfig.slidesPerView}
            slidesPerGroup={slidesConfig.slidesPerGroup}
            onSlideChange={onSlideChange}
            onSwiper={handleSwiperInit}
            initialSlide={(isRTL && sliderCards.length > sliderItemCount) ? sliderCards.length - 1 : 0} 
            className={`
                ${template === "TEMPLATE_10" ? "template-10" : ""} 
                ${thumbnailOrientation === "PORTRAIT" ? "portrait" : "landscape"}
              `}
          >
            {sliderCards?.map((show,index) => (
            <SwiperSlide
                key={index}
                className={!isSingleCard && index === activeIndex ? "translate-last" : ""}
            >
            {template === "TEMPLATE_10" ? (
                <GaiaCard
                data={show}
                thumbnailOrientation={thumbnailOrientation}
                season={false}
                metaData={true}
                type={show?.type}
                />
            ) : (
                <ShowCard
                data={show}
                thumbnailOrientation={thumbnailOrientation}
                season={false}
                metaData={true}
                type={show?.type}
                />
            )}
            </SwiperSlide>
            ))}
          </Swiper>
        )}
      </>
    );
  };

  return (
    <div className={`moreTopics-container ${template==="TEMPLATE_9" ? "template-9" : ""} ${isRTL ? (numCards > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl") : ""}`}>
      <div className="header">
        <h2 className="title"><Translate textKey={'more_topics'} /></h2>
        {template !== "TEMPLATE_9" && (
        <Link 
          to={`/category/${activeTopicData?.key}`}
          className={`view-all ${
            template === "TEMPLATE_10" 
              ? "gaia" 
              : template === "TEMPLATE_9" 
              ? "template_9" 
              : ""
          }`}
        >
          <Translate textKey={'view_all'} /> <ArrowIcon className="arrow-icon" />
        </Link>
        )}
        {template === "TEMPLATE_9" && (
                  <Link 
                  to={`/category/${activeTopicData?.key}`}
                >
                  <span className="show_all"><Translate textKey={"show_all"} /></span>
                </Link>
        )}
      </div>
      <Swiper
        key={isRTL ? isLTRActive ? "ltr" : "rtl" : ""}
        ref={topicsSwiperRef}
        modules={[Navigation]}
        navigation
        spaceBetween={30}
        slidesPerView="auto"
        // freeMode={true}
        onSlideChange={onSlideChange}
        onSwiper={handleSwiperInit}
        initialSlide={(isRTL && isLTRActive) ? topics.length - 1 : 0}
        className={`topicsList ${isRTL ? isLTRActive ? "ltr" : "rtl" : ""}`}
      >
        {topics.map(topic => (
          <SwiperSlide key={topic}>
            <span
              className={`${activeTopic === topic ? "active" : ""} ${
                template === "TEMPLATE_10"
                  ? "gaia"
                  : template === "TEMPLATE_9"
                  ? "template_9"
                  : ""
              }`}
              onClick={() => setActiveTopic(topic)}
            >
              {topic}
            </span>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={`mainCards ${windowSize <= 980 ? "hidden" : ""}`}>
        {renderCards()}
      </div>
      {windowSize <= 980 && (
        <div className="mainCards">
      <Swiper
      key={isRTL ? (numCards > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl") : ""}
      modules={[Navigation]}
      spaceBetween={10}
      slidesPerView={5}
      slidesPerGroup={5}
      navigation={true}
      onSlideChange={onSlideChange}
      onSwiper={handleSwiperInit}
      initialSlide={(isRTL && numCards > rowItemCountNumber) ? displayedData.length - 1 : 0}
      className={`mainCards ${thumbnailOrientation==="PORTRAIT" ? "portrait":"landscape"}`}
      breakpoints={{
        320: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 20 },
        480: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 20 },
        768: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 20 },
        980: { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 20 },
        1200: { slidesPerView: 5, slidesPerGroup: 5, spaceBetween: 20 },
      }}
    >
      {displayedData?.map((item,index)=>(
          <SwiperSlide key={index} className={isSingleCard ? "" : index === activeIndex ? "translate-last" : ""}>
            {template === "TEMPLATE_10" ? (
              <GaiaCard
                data={item}
                season={false}
                metaData={true}
                type={data?.type}
                thumbnailOrientation={thumbnailOrientation}
              />
            ) : (
              <ShowCard
                data={item}
                season={false}
                metaData={true}
                type={data?.type}
                thumbnailOrientation={thumbnailOrientation}
              />
            )}
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
      )}
      {template !== "TEMPLATE_9" && (
      <Link 
        to={`/category/${activeTopicData?.key}`} 
        className={`view-all-mobile ${
          template === "TEMPLATE_10" 
            ? "gaia" 
            : template === "TEMPLATE_9" 
            ? "template_9" 
            : ""
        }`}
      >
        <Translate textKey={'view_all'} /> <ArrowIcon className="arrow-icon" />
      </Link>
      )}
    </div>
  );
};

export default MoreTopics;
